/**
 * Hero component
 */

import gsap from 'gsap'

let heroComponent = {
  initialize () {
    this.el = document.querySelector('.block-hero')
    this.timeline = false

    if (this.el && this.el instanceof HTMLElement) {
      this.elements = this.el.querySelectorAll('.elem')

      if (this.elements && this.elements instanceof NodeList) {
        let timeline = gsap.timeline({ pause: true })

        timeline.fromTo(
          this.elements,
          {
            autoAlpha: 0,
            y: 20
          },
          {
            delay: 0.25,
            duration: 2,
            autoAlpha: 1,
            y: 0,
            ease: 'power3.out',
            stagger: {
              amount: 0.3,
              ease: 'power2.inOut'
            }
          }
        )

        timeline.play()
      }
    }
  }
}

export default heroComponent
