/**
 * Lazyload component
 */

let lazyloadComponent = {
  /**
   * Initialize lazyload component
   * @param {LocomotiveScroll} scrollInstance
   * @return {Void}
   */
  initialize (scrollInstance) {
    this.scroll = scrollInstance

    this.attachHandlers()
  },

  /**
   * Attach event handlers
   * @return {Void}
   */
  attachHandlers () {
    this.scroll.on(
      'call',
      value => {
        if (value === 'lazyload') {
          this.processLazyload()
        }
      }
    )
  },

  /**
   * Process lazyload
   * @return {Void}
   */
  processLazyload () {
    let elements = document.querySelectorAll('.lazyload')

    // Process each elements
    _.each(
      elements,
      element => {
        let media = element.querySelector('img')

        // remove lazyload classname as soon as processing item start
        element.classList.remove('lazyload')

        // process media element
        if (media instanceof HTMLImageElement && media.dataset.src) {
          media.addEventListener(
            'load',
            () => {
              element.classList.add('ready')
            }
          )

          media.setAttribute('src', media.dataset.src)
          media.removeAttribute('data-src')

          if (media.dataset.srcset) {
            media.setAttribute('srcset', media.dataset.srcset)
            media.removeAttribute('data-srcset')
          }
        }
      }
    )
  }
}

export default lazyloadComponent
