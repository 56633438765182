/**
 * Application main script
 */
import LocomotiveScroll from 'locomotive-scroll'
import SwiperSlider from 'swiperslider'
import heroComponent from '~/components/hero'
import lazyloadComponent from '~/components/lazyload'

(
  function ($) {
    console.log('*********************************************')
    console.log('**** яαvoяoηα • https://www.ravorona.com ****')
    console.log('*********************************************')

    /**
     * DOM ready
     */
    $(
      function () {
        const scrollInstance = new LocomotiveScroll(
          {
            el: document.querySelector('.app-wrapper'),
            smooth: false
          }
        )

        /**
         * Scroll call handler
         */
        scrollInstance.on(
          'call',
          (value, direction) => {
            console.log('scroll', value)
            switch (value) {
              case 'header-bg':
                if (direction === 'exit') {
                  document.body.classList.add('header-bg')
                } else {
                  document.body.classList.remove('header-bg')
                }
                break
            }
          }
        )

        /**
         * Init components
         */
        heroComponent.initialize()
        lazyloadComponent.initialize(scrollInstance)

        /**
         * Force scroll instance update
         */
        scrollInstance.update()

        /**
         * Handle window resize
         */
        $(window).resize(
          () => {
            $('body').removeClass('menu-visible')

            if (scrollInstance) {
              scrollInstance.update()
            }
          }
        )

        /**
         * Toggle menu button
         * Toggle main menu visibility in mobile device
         */
        $('.toggle-menu').click(
          () => {
            $('body').toggleClass('menu-visible')
          }
        )

        /**
         * Expandable list
         */
        $('.expandable-list').each(
          (index, expandable) => {
            let list = $('li', expandable)
            let button = $('button', expandable)

            if (list && list.length > 6) {
              $(expandable).addClass('collapsed')
            }

            button.click(
              () => {
                $(expandable).removeClass('collapsed')
              }
            )
          }
        )

        /**
         * Show gallery zoom
         */
        $('.gallery-item').click(
          () => {
            $('body').addClass('gallery-zoom-visible')
          }
        )

        /**
         * Hide gallery zoom
         */
        $('.gallery-zoom .close').click(
          () => {
            $('body').removeClass('gallery-zoom-visible')
          }
        )

        /**
         * Gallery slider
         */
        new SwiperSlider(
          '.gallery-container',
          {
            loop: false,
            pagination: {
              el: '.gallery-pagination',
              dynamicBullets: true,
              clickable: true
            },
            navigation: {
              nextEl: '.gallery-next',
              prevEl: '.gallery-prev'
            },
            slidesPerView: 2,
            breakpoints: {
              768: { slidesPerView: 3 },
              1024: { slidesPerView: 4 },
              1280: { slidesPerView: 6 }
            }
          }
        )

        /**
         * Gallery zoom slider
         */
        new SwiperSlider(
          '.gallery-zoom-container',
          {
            loop: false,
            pagination: {
              el: '.gallery-zoom-pagination',
              dynamicBullets: true,
              clickable: true
            },
            navigation: {
              nextEl: '.gallery-zoom-next',
              prevEl: '.gallery-zoom-prev'
            },
            slidesPerView: 1
          }
        )

        /**
         * Places slide
         */
        new SwiperSlider(
          '.places-carousel',
          {
            loop: false,
            pagination: false,
            slidesPerView: 1,
            grabCursor: true,
            breakpoints: {
              460: { slidesPerView: 2 },
              768: { slidesPerView: 3 }
            },
            navigation: {
              nextEl: '.places-carousel .swiper-button-next',
              prevEl: '.places-carousel .swiper-button-prev'
            }
          }
        )

        /**
         * Hosts slide
         */
        new SwiperSlider(
          '.hosts-carousel',
          {
            loop: false,
            pagination: false,
            slidesPerView: 2,
            grabCursor: true,
            breakpoints: {
              640: { slidesPerView: 3 },
              768: { slidesPerView: 4 }
            },
            navigation: {
              nextEl: '.hosts-carousel .swiper-button-next',
              prevEl: '.hosts-carousel .swiper-button-prev'
            }
          }
        )
      }
    )
  }
)(jQuery)
